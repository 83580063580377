/*
 * @Autor: suhang
 * @Date: 2022-05-17 15:42:30
 * @LastEditors: suhang
 * @LastEditTime: 2022-09-19 17:31:55
 * @Description:
 */

import Vue from "vue";
import {
	createRouter,
	createWebHashHistory,
	createWebHistory,
	RouteRecordRaw,
} from "vue-router";
import { notFoundRoutes } from "./module/notFoundRoutes";
import { i18n } from "../i18n";
const Index = () =>
	import(/* webpackChunkName: "index" */ "@/views/index/Index.vue");
const QuanZi = () =>
	import(/* webpackChunkName: "index" */ "@/views/index/quanzi.vue");
const DriveBooking = () =>
	import(/* webpackChunkName: "purchase" */ "@/views/purchase/DriveBooking");
const MyActivitiesDetail = () =>
	import(/* webpackChunkName: "my" */ "@/views/my/myActivitiesDetail.vue");
const Privacy = () =>
	import(/* webpackChunkName: "privacy" */ "@/views/privacy/privacy.vue");
const SubscriptionAgreement = () =>
	import(
		/* webpackChunkName: "subscriptionAgreement" */ "@/views/subscriptionAgreement/index.vue"
	);
// 选配页面
// const Optional = () => import(/* webpackChunkName: "optional" */ "@/views/optional/optional.vue")
// // 选配2
// const Optional2 = () => import(/* webpackChunkName: "optional" */ "@/views/optional2/optional.vue")

// 选配页面
import Optional from "@/views/optional/optional.vue";
import { TestRoutes } from "./module/testRoutes";
import { ToolRoutes } from "./module/toolRoutes";
// 选配2
// import Optional2 from "@/views/optional2/optional.vue"
import LoginHelp from "@/views/login/help.vue";
import User from "@/views/privacy/user.vue";
import Shequ from "@/views/privacy/shequ.vue";
import Registered from "@/views/my/registered.vue";

// 支付成功
const PaySuccess = () =>
	import(
		/* webpackChunkName: "paySuccess" */ "@views/paySuccess/paySuccess.vue"
	);
// 选择成功
const SelectSuccess = () =>
	import(
		/* webpackChunkName: "selectSuccess" */ "@/views/selectSuccess/selectSuccess.vue"
	);
// 测试
const Test = () =>
	import(/* webpackChunkName: "test" */ "@/views/test/test.vue");
const Skip = () =>
	import(/* webpackChunkName: "skip" */ "@/views/skip/skip.vue");
const Open = () =>
	import(/* webpackChunkName: "skip" */ "@/views/open/open.vue");

const routes = [
	{ path: "/", redirect: "/index" },
	{
		path: "/404",
		name: "404",
		component: () => import(/* webpackChunkName: "404" */ "@/views/404"),
		meta: {
			title: "页面不存在",
		},
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
	{
		path: "/index",
		name: "index",
		component: Index,
		// meta: {
		// 		title: "茵末"
		//   },
		children: [
			{ path: "", component: QuanZi },
			{
				path: "/quanzi",
				name: "quanzi",
				component: QuanZi,
				meta: {
					title: "茵末h5",
				},
			},
			{
				path: "/drive-booking",
				name: "drive-booking",
				component: DriveBooking,
				meta: {
					title: "预约试驾",
				},
			},
			{
				path: "/myActivitiesDetail",
				name: "myActivitiesDetail",
				component: MyActivitiesDetail,
				// meta: {
				// 		title: "详情页"
				//   }
			},
			{
				path: "/privacy",
				name: "privacy",
				component: Privacy,
				meta: {
					title: "隐私协议",
				},
			},
			{
				path: "/user",
				name: "user",
				component: User,
				meta: {
					title: "用户协议",
				},
			},
			{
				path: "/shequ",
				name: "shequ",
				component: Shequ,
				meta: {
					title: "社区隐私协议",
				},
			},
			{
				path: "/subscriptionAgreement",
				name: "subscriptionAgreement",
				component: SubscriptionAgreement,
				meta: {
					title: "订购协议",
				},
			},
			{
				path: "/loginHelp",
				name: "loginHelp",
				component: LoginHelp,
				meta: {
					title: "注册登录帮助",
				},
			},
			{
				path: "/optional",
				name: "optional",
				component: Optional,
				meta: {
					title: "选配",
				},
			},
			// {
			// 	path: '/optional2', name: 'optional2', component: Optional2,
			// 	meta: {
			// 		title: "配置和订购"
			// 	}
			// },
			{
				path: "/paySuccess",
				name: "paySuccess",
				component: PaySuccess,
				meta: {
					title: "支付成功",
				},
			},
			{
				path: "/selectSuccess",
				name: "selectSuccess",
				component: SelectSuccess,
				meta: {
					title: "选择成功",
				},
			},
			{
				path: "/skip",
				name: "skip",
				component: Skip,
				meta: {
					title: "",
				},
			},
			{
				path: '/open',
				name: 'open',
				component: Open,
				meta: {
					title: ''
				}
			},
			{
				path: '/registered',
				name: 'registered',
				component: Registered,
				meta: {
					title: '已报名车主'
				}
			}
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

// 404页面路由，必须最后添加
// notFoundRoutes.map((item) => router.addRoute(item))
TestRoutes.map((item) => router.addRoute(item));
ToolRoutes.map((item) => router.addRoute(item));

router.afterEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title as string;
	}
});

export default router;
