/*
 * @Autor: suhang
 * @Date: 2022-09-15 17:37:41
 * @LastEditors: suhang
 * @LastEditTime: 2022-09-15 18:03:24
 * @Description: 测试路由，只在SIT和UAT环境开放
 */

import { RouteRecordRaw } from "vue-router"
import TestIndex from '@views/test/index'

export const TestRoutes: Array<RouteRecordRaw> = [
    {
        path: "/test/",
        redirect: "index",
        component: TestIndex,
        children: [
            {
                path: "index",
                component: () =>
                    import(
                        /* webpackChunkName: "test" */ "@views/test/test.vue"
                    ),
                meta: {
                    title: "测试"
                }
            },
            {
                path: "bridge",
                component: () =>
                    import(
                        /* webpackChunkName: "test" */ "@views/test/Bridge"
                    ),
                meta: {
                    title: "Bridge测试页面"
                }
            },
        ],
    },
]
