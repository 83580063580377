<template>
    <div class="container">
        <div class="title">
            <span>已报名车主·</span>
            <span>共 {{ num }} 人</span>
        </div>
        <div class="user-list" v-if="userList.length > 0">
            <div class="item" v-for="(item, index) in userList" :key='index'>
                <img :src="item.userHeadPortrait" v-if="item.userHeadPortrait" mode="aspectFit" />
                <!-- <img src="../../static/missing-face.png" v-else /> -->
                <div>{{ item.userName }}</div>
                <!--  -->
                <div class="right" v-if="userid == item.userId && item.activityStatus == 1">
                    <div class="cancel" @click="goCancel">取消报名</div>
                </div>
            </div>
        </div>
        <div class="none" v-else>暂时没有已报名的车主哦~</div>
        <div style="height:150px"></div>
    </div>
</template>

<script>
import { getActivityUserInfoApi, cancleActivityUserInfoApi } from '@/api/h5'
import { Dialog, Icon } from "vant"
export default {
    data() {
        return {
            num: 0,
            userList: [
                {
                    id: 1,
                    activityId: 1,
                    userId: 123,
                    isDelete: 0,
                    creater: "",
                    createTime: "2022-04-27 13:49:20",
                    modifyTime: "",
                    userName: "测试",
                    userHeadPortrait: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTI4ZWRY9GFX7WyF9UrgeoEfs2VNticiaBrYziaxPuGhXtB2nK98vMiaO6wIJcGjANgddXIGdTicGib4sNPA/132",
                    userType: "未购车"
                },
                {
                    id: 2,
                    activityId: 1,
                    userId: 124,
                    isDelete: 0,
                    creater: "",
                    createTime: "2022-04-27 13:49:36",
                    modifyTime: "",
                    userName: "大熊",
                    userHeadPortrait: "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTI4ZWRY9GFX7WyF9UrgeoEfs2VNticiaBrYziaxPuGhXtB2nK98vMiaO6wIJcGjANgddXIGdTicGib4sNPA/132",
                    userType: "未购车"
                },
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},
                // {id:1,userName:'Wil沃尔特',userHeadPortrait:''},

            ],
            isIos: null, //运行平台
            pages: 1,
            limit: 10,
            totalPages: 0,
            id: null,
            access_token: null,
            userid: ""
        };
    },
    onShow() {
        // console.log('show')
    },
    onReachBottom() {
        // console.log("上划刷新")
        if (this.pages < this.totalPages) {
            this.pages = this.pages + 1
            this.getActivityUserInfo()
        }
    },
    mounted() {
        console.log()
        const options = this.$router.currentRoute.value.query;
        // console.log(options)
        this.id = options.id
        this.access_token = options.access_token
        this.userid = options.userid;
        this.getActivityUserInfo()
        // 判断运行平台

    },
    methods: {
        /* 获取该活动参加的用户列表 */
        getActivityUserInfo() {
            getActivityUserInfoApi({
                activityId: this.id, //活动id
                current: this.pages, //当前页
                size: this.limit //每页数量
            }).then(res => {
                if (res.data.code == 200) {
                    console.log('获取该活动参加的用户列表')
                    console.log(res.data)
                    this.num = res.data.data.total
                    this.totalPages = Math.ceil(res.data.data.total / this.limit)
                    console.log('总页数' + this.totalPages)
                    // console.log(res.data.data.records)
                    if (res.data.data.records.length > 0) {
                        this.userList = this.userList.concat(res.data.data.records)
                        // this.userList[1].activityStatus=2
                        // console.log(this.userList)
                    }

                    // console.log(this.userList)
                } else {
                    Dialog.confirm({
                        title: '提示',
                        message: res.data.msg,
                        showCancelButton: false
                    })
                }
            })
        },

        goCancel() {
            console.log(1111);

            Dialog.confirm({
                title: '取消报名',
                message: "确认取消吗？",
                // showCancelButton: false
            }).then(res => {
                // console.log(res)
                cancleActivityUserInfoApi({
                    activityId: this.id, //活动id
                    userId: this.userid, //用户id
                }).then(res => {
                    console.log(res)
                    if (res.data.code != 200) {
                        Dialog.confirm({
                            title: '提示',
                            message: "请求异常",
                            showCancelButton: false
                        })
                    } else {
                        Dialog.confirm({
                            title: '提示',
                            message: "取消成功",
                            showCancelButton: false
                        })
                        this.userList = []
                        this.getActivityUserInfo();

                        // 小程序里面
                        this.$jweixin.miniProgram.getEnv((res) => {
                            if (res.miniprogram) { //在小程序里
                                this.$jweixin.miniProgram.navigateBack();
                            }
                        });

                        // iOS环境
                        if (window.webkit) {
                            window.webkit.messageHandlers.back.postMessage({})
                        }

                        // Android环境
                        if (window.back) {
                            window.back.back("");
                        }

                    }
                })

            }).catch(err => {
                console.log(err)
            })
            return;

        },
        // 给请求回来的图片地址加上后台服务器地址，可以正常显示
        SetImg(value) {
            var str = getApp().globalData.https + '/api/upload' + value
            if (value != "") {
                return str
            }
        },
    },
};
</script>

<style scoped>
.container {
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
}

.title {
    margin-bottom: 30px;
    font-size: 42px;
    color: #3E3A39;
    font-weight: bold;
    text-align: left;
}

.user-list {
    width: 100%;
}

.item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    color: #1E1E1E;
    padding: 30px 0;
    border-bottom: 2px solid #E5E5E5;
}

.item img {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    margin-right: 20px;
}

.none {
    width: 100%;
    div-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #999999;
}

.right {
    flex: 1;
    text-align: right;
}

.cancel {
    display: inline-block;
    background: #F4F8FB;
    border-radius: 44px;
    color: #9E9D9A;
    padding: 6px 22px;
    /* border-radius: 5px; */
    font-size: 24px;
}
</style>
