<template>
    <!-- 注册登录帮助 -->
    <div class="title"></div>
    <div class="content">
        <div class="item">
            1、您在使用APP时，手机号将作为您的唯一身份标识，并且在您绑定车辆时会与您的购车信息进行匹配，所以请您填写真实有效的手机号；
        </div>
        <div class="item">
            2、首次登录时需要通过短信验证码免密验证，未注册的手机号会帮您注册账号，已注册的手机号将直接登录；
        </div>
        <div class="item">3、注册成功后您可以选择设置密码，下次登录时通过手机号+密码即可登录；
        </div>
        <div class="item">4、如您忘记密码，可以在登录页面点击“忘记密码？”，按照提示进行密码重置；
        </div>
        <div class="item">5、若您无法收到短信验证码可以按照以下提示排查：
            <div class="item2">a：由于可能受到手机安全软件拦截，验证码短信被拦截进了短信垃圾箱；</div>
            <div class="item2">b：核实您的手机是否欠费，是否为可接受短信状态；</div>
            <div class="item2">c：核实您的手机存储空间是否足够。</div>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.title {
    margin: 40px 0 0;
    padding: 17px;
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #3E3A39;
}

.item {
    text-align: left;
    margin: 30px;
    font-size: 28px;
    font-weight: bold;
    color: #1E1E1E;
}

.item2 {
    // margin-left: 30px;
    color: #666666;
    margin: 10px 0 0;
}
</style>