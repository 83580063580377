<template>
  <div style="background: linear-gradient(#FBDFDF, #fff);">
    <div class="logo-box">
      <!-- <div class="logo-style">茵未</div> -->
      <img style="width:83px" src="@/assets/logo.png" />
      <!-- <div class="logo-style">
        <img src="@/assets/logo.png" />
      </div> -->
    </div>
    <div class="title">用户协议</div>
  </div>
  <div class="content-box">
    <div class="text-box text-style">
      日期：2022年12月2开发者公司名称:天津布尔科技有限公司
    </div>
    <div class="text-box text-style">
      开发者公司联系方式：022-23092628
    </div>
    <div class="text-box text-style">
      运营方公司名称：北京汽车制造厂德州分公司
    </div>
    <div class="text-box text-style">
      运营方公司联系方式：400-990-19510日
    </div>
    <div class="text-box text-style">
      这是您与北京汽车制造厂有限公司德州分公司（下文简称“北汽制造公司”、或“我们”）之间的一份协议。使用车主生活之前，请阅读这些车主生活的使用条款，以及车主生活上（统称为此“协议”）提供的其他适用的规则、政策和条款。使用车主生活即视为您同意受本协议条款的约束。如果您不接受本协议条款，您可以停止使用车主生活。您需要注册一个北汽制造账户并登录来使用车主生活。您对任何服务的使用都必须遵守适用于这些服务的任何附加规则、政策和条款。
    </div>
    <div>车主生活使用条款如下：</div>
    <div>“车主生活”是指北京汽车制造厂有限公司德州分公司提供的APP。“服务”指我们为北汽设备用户提供的软件、支持和其他服务，包括但不限于“发现”或“社区”的功能。</div>
    <div>“软件”是指我们为您提供的所有可在北汽设备上使用的软件，但不包括第三方数字内容。</div>
    <div>1.功能与软件</div>
    <div>
      a.新手教学。您的北汽制造设备可能属于交通工具或是休闲娱乐工具，您需要通过学习和练习来掌握驾驶技巧。我们或我们的关联公司都不对由于使用者缺乏经验或未能遵循产品说明书或《用户手册》中的使用说明所造成的伤害或损害负责。如果您使用北汽制造设备，您应对安全驾驶负全责，遵守所有您所在地区的法律法规及交通规则，并根据您自己的判断完成驾驶和路线选择。
    </div>
    <div>
      b.分享服务。您的北汽制造设备可能具有一些功能，可以让您通过“车主社区”或其他功能与其他用户分享您的体验。如果您选择上传您的活动，这意味着您的信息（包括但不限于帖子、图片、视频和评论）将会传输至服务器，并对您所在地区或全球用户可见。
    </div>
    <div>c. 发现服务。北汽制造设备的部分应用程序中的某些功能，可以让您发现附近的其他用户或活动。您可以使用这些功能来访问在线商城或社区。</div>
    <div>d. 软件的使用。除本协议另有规定外，您只能在北汽设备上使用这些软件。对于适用于软件的附加条款，请参见北汽设备或用户手册的配套应用程序中的法律或类似部分中的条款。</div>
    <div>2.连接性和可用性</div>
    <div>a. 互联网和无线连接。您的北汽制造设备中的一些功能可能需要来自第三方提供商的互联网连接或数据流量才能正常使用。在此情况下，您的互联网连接或数据流量取决于由您的供应商规定的费用、限制条件、条款和限制因素。</div>
    <div>
      b.可用性。一些服务可能不可用、（根据设备型号和所在地区）有所差异、限时提供或需要单独订阅。此外，如果您的北汽设备需要无线数据服务，并且位于无法访问此类服务的区域，那么您可能无法使用北汽设备的部分或全部服务或其他功能。我们对由于无线数据服务不可用或相应的服务失效不承担责任。
    </div>
    <div>3.知识产权</div>
    <div>
      a.您可以用这些软件发布您的原创作品、意见和观点，或发布数据、文字、信息、签名、照片、个人信息、音频、视频、链接和其他信息内容。您应保证您上传的信息所有权和所有知识产权都是您所拥有或已获得合法授权的。任何使用软件和服务的行为都不会构成对我们或任何第三方的合法权利的侵犯。在任何第三方基于您发布的上述信息提出侵权异议或投诉的情况下，我们有权删除上述任何数据或内容，并有权追究您的相关法律责任。对于由于您的行为所造成的相关损失（不管是故意的还是疏忽的），您应当进行赔偿并承担对受损方的法律责任。
    </div>
    <div>b.所有由您发布的原始内容（包括但不限于文字、图片和视频）的知识产权归您所有，您可以以任何形式向任何第三方授予或授权您的原始内容，无需我们同意。</div>
    <div>
      c.当您使用我们的服务或分享、发布或上传任何与我们的服务有关的受知识产权保护的内容时，将视为您授予我们非独占、免版税、不可撤销、可转让、可次级授权和全球使用的许可。根据本许可，我们被授权以任何形式在任何产品、服务和推广中使用此类内容，包括但不限于网站、发布的应用程序或其他互联网产品或社交媒体软件。
    </div>
    <div>4.基本条款</div>
    <div>a.
      接收的信息。软件将为我们提供有关您使用北汽制造设备及相关服务的交互信息。这些信息可能会用于云端处理，以提升您的服务体验并改进我们的产品和服务。这些信息可能会存储到您所在地区之外的服务器上。我们会按照我们的隐私政策处理所收到的信息。
    </div>
    <div>b. 修正。我们可以自行决定修改及更新本协议的任何条款，并将修改后的条款公布在网站上。如果您在修订版协议生效日期后继续使用北汽制造设备，即表明您已接受新的条款。</div>
    <div>c.
      终止。如果您在发布或以任何方式使用任何内容或信息时，未遵守使用条款或我们的政策（包括社区规则），或在任何法律法规或政策要求或规定下，我们有权删除这些内容或信息。在下列情况下，我们有权立即拒绝或终止我们向您提供的部分或所有服务（包括终止或限制您访问账户）：
    </div>
    <div>(a) 保护我们的社区或服务；</div>
    <div>(b) 您的行为造成法律风险或违反法律、法规或政策要求；</div>
    <div>(c) 您违反了本使用条款或我们的政策（包括社区规则）；</div>
    <div>(d) 您侵犯了他人的知识产权或其他合法权益；</div>
    <div>(e) 法律规定或其他法律要求。</div>
    <div>如果我们采取措施限制或终止您的账户使用，我们将按照本使用条款和我们的隐私政策通知您。</div>
    <div>服务终止的情况下，您将不会收到任何费用的退款。九号公司没有坚决要求或强迫您严格遵守本协议的，不构成对任何此项权利的放弃。</div>
    <div>d. 争议/强制性仲裁</div>
    <div>
      本协议受中华人民共和国法律管辖并按照中华人民共和国法律解释。因本协议引起的或与本协议有关的任何争议，应提交中国国际经济贸易仲裁委员会进行仲裁，仲裁地在北京，仲裁应按照申请仲裁时现行有效的仲裁规则进行。最终仲裁裁决对双方都具有约束力。您签订本协议或使用北汽制造设备或服务即视为已同意这些条款。
    </div>
    <div>e. 免责条款和豁免条款</div>
    <div>
      我们的软件或网站或用户手册中有关北汽制造公司的信息和资料可能包含不准确和排版印刷错误。我们不保证此类信息和资料的准确性、完整性或有效性，我们保留自行对任何错误或遗漏进行纠正的权利。我们可能随时对软件或网站或用户手册中描述的材料、产品、程序、服务或价格（如有）进行任何其他更改，恕不另行通知。
    </div>
    <div>
      我们的软件或网站或用户手册中的信息和资料系基于“现状”提供，我们不提供任何形式的担保，无论是陈述或保证、明示或暗示，包括但不限于适销性、非侵权性或适用于任何特定目的保证。某些司法管辖区不允许排除默示担保，因此上述排除可能不适用于您。
    </div>
    <div>在任何情况下，我们都不承担因使用或不能使用本软件而引起的任何间接、从属或惩罚性损害，包括但不限于利润损失、业务损失或商誉损失。</div>
    <div>f. 联系信息。若需要有关北汽制造设备、服务、软件或解决其他问题的帮助，请通过如下方式联系我们：</div>
    <div>北京汽车制造厂有限公司德州分公司</div>
    <div>电话：400-990-1951</div>
    <div>地址：山东德州陵城区迎宾街北首路东</div>
  </div>
</template>

<script>
export default {
  name: "privacy"
}
</script>

<style scoped>
.text-style {
  text-indent: 1rem;
}

.text-style1 {
  text-indent: 0.4rem;
}

.logo-box {
  margin: 0 auto;
  padding-top: 1rem;
  box-sizing: border-box;
}

.logo-style {
  width: 2rem;
  height: 0.7rem;
  margin: 0 auto;
  line-height: 0.7rem;
  font-size: 0.4rem;
  border-radius: 100px;
  color: #fff;
  background: #3a92db;
}

.title {
  font-size: 0.6rem;
  font-weight: 700;
  margin: 0 0.4rem;
  margin-top: 0.8rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.content-box {
  text-align: left;
  line-height: 0.5rem;
  word-wrap: break-word;
  padding: 0.4rem 0.4rem 1rem 0.4rem;
}

.text-box {
  text-align: left;
}

.title1 {
  font-size: 0.4rem;
  font-weight: 700;
  padding: 0.5rem 0;
}
</style>