/*
 * @Autor: suhang
 * @Date: 2022-05-17 15:42:30
 * @LastEditors: suhang
 * @LastEditTime: 2022-09-20 08:16:18
 * @Description: 判断平台的store
 */
import { defineStore } from "pinia";

export const usePlatformStore = defineStore("platform", {
    state: () => {
        return {
            isAndroid: false, // 当前是否为安卓环境(只要是在Android下就位true，不管是不是webview)
            isAndroidApp: false, // 当前是否为安卓 APP环境
            isIos: false, // 当前是否为IOS环境(只要是在IOS下就为true，不管是不是webview)
            isIosApp: false, // 当前是否为IOS APP环境
            isApp: false, // 当前是否为App环境(isAndroid或者isIos为true，则此字段为true)
            isWeApp: false, // 当前是否为微信小程序环境
            isWeH5: false, // 当前是否为微信H5环境
            isBrowser: false, // 当前是否为正常浏览器环境
            appVersionName: "", // APP的版本号，isApp为true才会有版本号
        }
    },
    getters: {
    },
    actions: {}
})