/*
 * @Autor: suhang
 * @Date: 2022-05-18 13:56:27
 * @LastEditors: suhang
 * @LastEditTime: 2022-05-20 14:10:35
 * @Description: 中文
 */

export default {
    title: {
        purchase: {
            driveBooking: "预约试驾"
        }
    },
    tooltip: {
        pleaseSelect: "请选择{name}",
        pleaseInput: "请输入{name}",
        retryAfterSeconds: "请于{second}秒后重新尝试",
        canNotNull: "{name}不能为空",
        notValidPhone: "手机号格式错误",
        notValidSMSCode: "验证码格式错误"
    },
    common: {
        submit: "提交",
        sendSMSCode: "发送验证码",
    },
    purchase: {
        driveBooking: {
            driveVehicle: "试驾车型",
            driveStore: "试驾门店",
            personName: "姓  名",
            personTel: "手机号",
            SMSCode: "验证码",
            driveTime: "试驾时间",
            driveWay: "试驾方式",
        }
    }
}