/*
 * @Autor: suhang
 * @Date: 2022-05-20 14:58:07
 * @LastEditors: suhang
 * @LastEditTime: 2022-06-15 14:09:55
 * @Description: 
 */
// @ts-nocheck

import { HttpService } from "@utils/http";
import { Resp } from '@typings/public'


type getActivityInfoByIdParam = {
    activityId?: number,//活动id
}

type getBannerInfoParam = {
    id?: string,//id
}

type getInformationStreamContentsByIdParam = {
    contentsId?: string,//id
}
type ChooseData = {
    modelId?: string | number,//车型id
}
type ChooseDictData = {
    parentId?: string | number,//父级id
}


type CarSorderDetailDTO = {
    name?: string; // 商品名称
    showPicture?: string; // 商品展示图
    produceId?: string; // 选购商品id
    producePrice?: string;// 商品价格
    isProduce?: string;// 是否为车辆商品	
    sortOrder?: string;// 商品选配顺序
}
type CarSorderPayDTO = {
    intentPayment?: number;//选配车辆意向金金额
    paymentAmount?: number;//选配车辆预付款金额
    advancePayment?: number;//订单总价格
}
type SetTempStorageData = {
    id?: string | number,//id
    carModelId?: string,
    carModelName?: string,
    carModelShowPicture?: string;
    carSorderPayDTO?: CarSorderPayDTO;
    details?: CarSorderDetailDTO[];
}


// /** 获取活动详情*/
// export function getActivityInfoByIdApi(data: getActivityInfoByIdParam) {
//     return HttpService.get<Resp>("/ActivityInfo/getActivityInfoById", { params: data })
// }
// /** 获取banner详情*/
// export function getBannerInfoApi(data: getBannerInfoParam) {
//     return HttpService.get<Resp>("/content/bannerinfo/" + data.id, { params: '' })
// }
// /** 获取指定信息流内容详情*/
// export function getInformationStreamContentsByIdApi(data: getInformationStreamContentsByIdParam) {
//     return HttpService.get<Resp>("/InformationStreamContents/getInformationStreamContentsById", { params: data })
// }


// // 选配
// /** 获取商品选配信息 */
// export function getChoose(data: ChooseData) {
//     return HttpService.get<Resp>("/goods/produce/choose", { params: data })
// }
// /** 可选配项字典信息获取 */
// export function getChooseDict(data: ChooseDictData) {
//     return HttpService.get<Resp>("/blade-system/dict-biz/dict/child", { params: data })
// }
// /** 车辆选配信息临时存储 */
// export function setTempStorage(data: SetTempStorageData) {
//     return HttpService.post<Resp>("/so/car-sorder-info/temporary/storage", { ...data })
// }

/** 获取活动详情*/
export const getActivityInfoByIdApi = (data: getActivityInfoByIdParam) => {
    return HttpService.get<Resp>("/ActivityInfo/getActivityInfoById", { params: data })
}
/** 获取banner详情*/
export const getBannerInfoApi = (data: getBannerInfoParam) => {
    return HttpService.get<Resp>("/content/bannerinfo/" + data.id, { params: '' })
}
/** 获取指定信息流内容详情*/
export const getInformationStreamContentsByIdApi = (data: getInformationStreamContentsByIdParam) => {
    return HttpService.get<Resp>("/InformationStreamContents/getInformationStreamContentsById", { params: data })
}
/** 获取已报名车主*/
export const getActivityUserInfoApi = (data: getActivityInfoByIdParam) => {
    return HttpService.get<Resp>("/ActivityUser/getActivityUserInfo", { params: data })
}
/** 取消报名车主*/
export const cancleActivityUserInfoApi = (data: getActivityInfoByIdParam) => {
    return HttpService.delete<Resp>("/ActivityUser/cancleActivityUserInfo", { params: data })
}


// 选配
/** 获取商品选配信息 */
export const getChoose = (data: ChooseData) => {
    return HttpService.get<Resp>("/goods/produce/choose", { params: data })
}
/** 可选配项字典信息获取 */
export const getChooseDict = (data: ChooseDictData) => {
    return HttpService.get<Resp>("/blade-system/dict-biz/dict/child", { params: data })
}
/** 车辆选配信息临时存储 */
export const setTempStorage = (data: SetTempStorageData) => {
    return HttpService.post<Resp>("/so/car-sorder-info/temporary/storage", { ...data })
}


/** 详情 */
export const selectById = (data: any) => {
    return HttpService.get<Resp>("/postInfo/app/noLogin/selectById", { params: data })
    // return HttpService.get('/API/Page/GetPages')
}