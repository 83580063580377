/*
 * @Autor: suhang
 * @Date: 2022-09-15 19:14:21
 * @LastEditors: suhang
 * @LastEditTime: 2022-09-20 08:39:37
 * @Description:
 */
import "./App.scss"
import { defineComponent, withCtx } from "vue"
import { usePlatformStore } from "@store/platform"
import { storeToRefs } from "pinia"
import wx from "weixin-js-sdk"
import { Loading, Overlay } from "vant"
import { useCommonStore } from "@store/common"
// 1. 圈子内活动报名、等各种活动报名状态
// 2. 登录页面的用户协议、隐私政策、社区隐私协议、登录帮助

export default defineComponent({
    setup() {
        // 初始化对H5运行环境的判断
        const platformStore = usePlatformStore()
        const storeRef = storeToRefs(platformStore)
        const agent = navigator.userAgent
        if (agent.match(/android/gi)) {
            storeRef.isAndroid.value = true
            if (agent.match(/BicoseApp/g)) {
                storeRef.isAndroidApp.value = true
                storeRef.isApp.value = true
            } else if (agent.match(/weapp/gi)) {
                storeRef.isWeApp.value = true
            }
        } else if (agent.match(/ios/gi)) {
            storeRef.isIos.value = true
            if (agent.match(/BicoseApp/g)) {
                storeRef.isIosApp.value = true
                storeRef.isApp.value = true
            }
        } else {
            storeRef.isBrowser.value = true
        }

        if (storeRef.isApp) {
            // 设置版本号
            storeRef.appVersionName.value =
                agent.match(/BicoseApp\/([\d\.]+)/)?.[1] || ""
        }

        if (agent.match(/micromessenger/i)) {
            // 微信环境的判断
            wx.miniProgram.getEnv((res: any) => {
                if (res.miniProgram) {
                    storeRef.isWeApp.value = true
                } else {
                    storeRef.isWeH5.value = true
                }
            })
        }
        console.log("环境信息=", storeRef)

        const commonStore = useCommonStore()

        return () => (
            <>
                <Overlay
                    show={commonStore.$state.publicLoadingState}
                    customStyle={{ backgroundColor: "rgba(0, 0, 0, .1)" }}
                >
                    <div class="loading-wrapper">
                        <Loading color="#1989fa" size="0.8rem" vertical>
                            {commonStore.$state.publicLoadingText}
                        </Loading>
                    </div>
                </Overlay>
                <router-view />
            </>
        )
    },
})
