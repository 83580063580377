/*
 * @Autor: suhang
 * @Date: 2022-05-18 13:56:06
 * @LastEditors: suhang
 * @LastEditTime: 2022-06-15 14:24:55
 * @Description: 配置i18n多语言
 */

import { createI18n } from "vue-i18n"
import ch from './locale/ch'
import en from './locale/en'
import { Locale } from 'vant'
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'

// Locale.use("en-US", enUS)
Locale.use("zh-CN", zhCN)

export const i18n = createI18n({
    fallbackLocale: "ch",
    globalInjection: true,
    legacy: false,
    locale: "ch",
    messages: {
        ch,
        en
    }
})
