/*
 * @Autor: suhang
 * @Date: 2022-09-19 17:26:35
 * @LastEditors: suhang
 * @LastEditTime: 2022-09-20 10:55:54
 * @Description: 工具路由
 */
import { RouteRecordRaw } from "vue-router"
import ToolIndex from "@views/tools/index"
const PDFPreview = () => import(/* webpackChunkName: "tool" */ "@views/tools/PDFPreview")

export const ToolRoutes: Array<RouteRecordRaw> = [
    {
        path: "/tools/",
        component: ToolIndex,
        children: [
            {
                path: "pdf-preview",
                component: PDFPreview,
                meta: {
                    title: "",
                },
            },
        ],
    },
]
