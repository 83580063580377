/*
 * @Autor: suhang
 * @Date: 2022-09-20 08:16:05
 * @LastEditors: suhang
 * @LastEditTime: 2022-09-20 08:44:25
 * @Description: 公共状态管理
 */
import { defineStore } from "pinia";

export const useCommonStore = defineStore("common", {
    state: () => {
        return {
            publicLoadingState: false, // 控制公共加载弹层的展示或者关闭
            publicLoadingText: "", // 控制公共加载弹层的提示文字
        }
    },
    actions: {
        // 修改加载弹框显示状态
        setPublicLoadingState(state: boolean) {
            this.publicLoadingState = state
        },
        // 修改加载弹框文字
        setPublicLoadingText(text: string = "") {
            this.publicLoadingText = text
        }
    }
})