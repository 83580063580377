/*
 * @Autor: suhang
 * @Date: 2022-05-18 13:56:49
 * @LastEditors: suhang
 * @LastEditTime: 2022-05-20 14:11:03
 * @Description: 
 */

export default {
    title: {
        purchase: {
            driveBooking: "Drive Booking"
        }
    },
    tooltip: {
        pleaseSelect: "Please Select {name}",
        pleaseInput: "Please Input {name}",
        retryAfterSeconds: "please retry after {second} seconds",
        canNotNull: "{name} can not be bull",
        notValidPhone: "not a valid phone number",
        notValidSMSCode: "not a valid SMS code"
    },
    common: {
        submit: "Submit",
        sendSMSCode: "Send SMS Code",
    },
    purchase: {
        driveBooking: {
            driveVehicle: "Drive Vehicle",
            driveStore: "Drive Store",
            personName: "Name",
            personTel: "Telephone",
            SMSCode: "SMSCode",
            driveTime: "Drive Time",
            driveWay: "Drive Way",
        }
    }
}