<template>
  <div style="background: linear-gradient(#FBDFDF, #fff);">
    <div class="logo-box">
      <!-- <div class="logo-style">茵未</div> -->
      <img style="width:83px" src="@/assets/logo.png" />
      <!-- <div class="logo-style">
        <img src="@/assets/logo.png" />
      </div> -->
    </div>
    <div class="title">社区管理规范</div>
  </div>
  <div class="content-box">
    <div class="text-box text-style">
      BAW Live App 致力于提供一个分享关于北汽制造产品相关的高质量内容社区。为了保护用户创造的内容、维护良好的社区氛围， 北汽制造团队和北汽制造授权的社区管理员将依据BAW Live App 协议和本规范中的条款对注册 BAW
      Live App 的用户和发布在BAW Live App 的内容进行管理。采取的措施包括但不限于更改或删除用户发布的内容、暂停或终止用户使用BAW Live App的权利。
    </div>
    <div class="title1">1 违规行为界定</div>
    <div class="text-style1">1.1 违反法律法规：发布违反法律法规，危害国家及社会安全的信息，包括但不限于：</div>
    <div class="text-style">反对宪法所确定的基本原则</div>
    <div class="text-style">危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一</div>
    <div class="text-style">损害国家荣誉和利益</div>
    <div class="text-style">煽动民族仇恨、民族歧视，破坏民族团结</div>
    <div class="text-style">破坏国家宗教政策，宣扬邪教和封建迷信</div>
    <div class="text-style">散布谣言，扰乱社会秩序，破坏社会稳定</div>
    <div class="text-style">宣扬淫秽、赌博、暴力、凶杀、恐怖或者教唆犯罪</div>
    <div class="text-style">发布有关规定中明确禁止的淫秽内容（如性器官、性行为、性心理的详细描写），或发布引诱性的图片、文字，引导用户进行线上线下的色情服务交易</div>
    <div class="text-style">煽动非法集会、结社、游行、示威、聚众扰乱社会秩序</div>
    <div class="text-style">诽谤他人，泄露他人隐私，侵害他人合法权益</div>
    <div class="text-style1">1.2 危害民族功德和民族优良文化传统：发布淫秽色情、血腥暴力、违禁违规信息等相关内容，包括但不限于：</div>
    <div class="text-style">散播淫秽、色情类信息，包括但不限于招嫖、裸聊，或偷拍、走光等内容。或在其他用户动态下评论有骚扰性质的文字信息</div>
    <div class="text-style">引人不适具有极度视觉冲击的血腥内容，或蓝鲸游戏、人体刺绣等记录自残、自杀的相关内容</div>
    <div class="text-style">发布国家法律明令禁止的违禁违规物品售卖信息</div>
    <div class="text-style">官方未证实的社会谣言类内容</div>
    <div class="text-style">发布揭露社会负面，使得舆论导向有偏颇的内容。例如：校园暴力视频、虐童视频等</div>
    <div class="text-style1">1.3 危害国家安全和政治稳定：发布涉及政治领导人负面信息，包括但不限于：</div>
    <div class="text-style">冒用国内外领导人形象注册账号</div>
    <div class="text-style">使用国内外领导人的姓名（含谐音字）作为账号注册名称的</div>
    <div class="text-style">传播涉及党和国家领导人不实谣言信息</div>
    <div class="text-style">传播有损领导人形象的歌谣、顺口溜、诗词、小品、段子</div>
    <div class="text-style">违反《治安管理处罚法》，有明确目的、组织性的行动，如：煽动、组织集体请愿、上访、游行、集会、罢工、罢市、抗法等</div>
    <div class="text-style1">1.4 侵害公民权利利益：在未经许可的情况下，发布涉及侵占其他用户或企业隐私权、肖像权、名誉权、知识产权等信息，包括但不限于：</div>

    <div class="text-style">通过互联网发表他人隐私、披露他人未同意公开的个人信息（身份证信息、工作单位、手机号码、邮件地址、亲友联系方式、姓名、照片等）</div>
    <div class="text-style">
      捏造事实公然发表过激言论、恶意诽谤中伤他人，丑化他人人格及形象，用粗俗、下流、威胁性、侮辱性言论，胁迫骚扰他人等方式损害他人名誉，为达到不正当竞争的目的，因交易发生矛盾而诽谤污蔑，侵犯商家名誉权的言论和行为</div>
    <div class="text-style">以非法手段上传、下载、在线点播、搜索链接（电影、歌曲、文章、书籍等）等侵犯他人著作权的；未经许注册、使用域名、侵犯了他人商标权的言论和行为</div>
    <div class="text-style">侵犯姓名权、名称权、肖像权，冒用或者盗用他人的名义和个人资料进行用户注册、仿冒他人发布文章、贴文等的言论和行为</div>
    <div class="text-style1">1.5 不友善行为：不尊BAW Live App 用户及其所贡献内容的行为，包括但不限于：</div>

    <div class="text-style">骚扰：以评论、@他人等方式对他人反复发送重复或者相似的诉求，或者以下流、淫秽语言挑逗同性/异性</div>
    <div class="text-style">谩骂：以不文明的语言对他人进行负面评价</div>
    <div class="text-style">羞辱：贬低他人的能力、行为、生理或身份特征，让对方难堪</div>
    <div class="text-style">歧视：针对他人的民族、种族、宗教、性取向、性别、年龄、地域、生理特征等身份或者归类的攻击</div>
    <div class="text-style">挑衅：以不友好的方式激怒他人，意图使对方对自己的言论做出回应，蓄意制造事端</div>
    <div class="text-style1">1.6 恶意行为：影响用户体验、扰乱 九号出行 App 社区秩序的信息或影响运营安全的行为，包括但不限于：</div>

    <div class="text-style">冒充BAW Live App 站内及相关业务官方账号或工作人员</div>
    <div class="text-style">冒充他人，通过头像、用户名等个人信息暗示自己与他人相等同或有关联</div>
    <div class="text-style">侵犯九号出行 知识产权，发布、使用、恶搞北汽制造 logo 等，或者容易与目前已有北汽制造产品设计主题、外观等混淆的内容</div>
    <div class="text-style">发布含有钓鱼网站、木马、病毒网站链接及相关内容</div>
    <div class="text-style">发布含有潜在危险、窃取用户隐私等相关内容</div>
    <div class="text-style">胡乱举报没有违规行为的用户和状态</div>
    <div class="text-style">以隐瞒事实或隐瞒真相的方式，骗取其他用户金钱财产的诈骗类信息</div>
    <div class="text-style1">1.7 发布垃圾信息：以营利或非营利为目的，发布影响用户体验、扰乱BAW Live App 社区秩序的信息或相关行为，包括但不限于：</div>

    <div class="text-style">发布无意义灌水内容（如纯数字、表情、符号、沙发等）</div>
    <div class="text-style">重复发布相似内容（包括但不限于发布动态、评论）</div>
    <div class="text-style">未经他人授权同意，抄袭他人的原创内容</div>
    <div class="text-style">拥有多个账号，或者与多个账号合作谋求不正当曝光</div>
    <div class="text-style">买卖账号，或使用同一账号、同一设备重复灌水的行为</div>
    <div class="text-style">未经管理员同意，发布的广告、推广、营销、宣传（如文字、图片、视频、QQ号、微信号、二维码、链接等）等影响其他用户体验的内容</div>

    <div class="title1">2 举报及处理</div>
    <div class="text-style1">1.1 举报方式：</div>
    <div class="text-box text-style">BAW Live App
      社区管理员通过主动发现和接受用户举报两种方式发现违规行为。您可以使用「举报」功能对涉嫌违规的行为进行分类举报。经查属实的，将酌情给予举报人奖励；对于举报不实的，不做处理；对于多次举报不实的举报人，将做恶意举报的违规处理，该用户账号将被禁言
      3 天。</div>

    <div class="text-style1">1.2 处理流程：违规信息由BAW Live App社区管理员根据本规定直接处理。一旦被认定发布以上内容或者有以上行为，该账号将被执行以下操作：</div>
    <div class="text-style">警告：违规内容被管理员删除后，该用户将在消息通知收到违规提醒；</div>
    <div class="text-style">禁言：情节严重的，如行为恶劣或多次违规，管理员将酌情对违规用户账号禁言 3 天或7
      天（禁言期间只能浏览，不能发布动态、点赞、评论，不能添加或取消关注，不能修改用户名、签名和头像，丧失禁言期内BAW Live App的任何活动获奖资格等。）；</div>
    <div class="text-style">永久封禁：已有多次禁言记录，仍继续违规的，管理员将删除相应内容，且该用户账号将被永久停用；如遇异常恶劣的情况，九号出行保留依法追究账号所有人责任的权利。</div>

    <div class="text-style">暂时没有被删除的违规内容，并不意味着北汽制造允许它的存在。如果您看到了符合违规标准但是没有被删除的内容，欢迎立即举报。我们会在收到举报之后 24 小时内处理。</div>
    <div class="text-style">本规范为动态文档，北汽制造团队 有权根据相关法律法规或产品运营的需要对其内容进行修改并更新，请反复查看以便获得最新信息。
      北汽制造希望创造一个有用、有趣的 App 社区，希望大家能够友善对待他人，一起维护这里来之不易的社区氛围。</div>
  </div>
</template>

<script>
export default {
  name: "privacy"
}
</script>

<style scoped>
.text-style {
  text-indent: 1rem;
}

.text-style1 {
  text-indent: 0.4rem;
}

.logo-box {
  margin: 0 auto;
  padding-top: 1rem;
  box-sizing: border-box;
}

.logo-style {
  width: 2rem;
  height: 0.7rem;
  margin: 0 auto;
  line-height: 0.7rem;
  font-size: 0.4rem;
  border-radius: 100px;
  color: #fff;
  background: #3a92db;
}

.title {
  font-size: 0.6rem;
  font-weight: 700;
  margin: 0 0.4rem;
  margin-top: 0.8rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.content-box {
  text-align: left;
  line-height: 0.5rem;
  word-wrap: break-word;
  padding: 0.4rem 0.4rem 1rem 0.4rem;
}

.text-box {
  text-align: left;
}

.title1 {
  font-size: 0.4rem;
  font-weight: 700;
  padding: 0.5rem 0;
}
</style>