/*
 * @Autor: suhang
 * @Date: 2022-09-19 17:27:50
 * @LastEditors: suhang
 * @LastEditTime: 2022-09-19 17:27:51
 * @Description: 
 */
import { defineComponent } from "vue";

export default defineComponent({
    render: () => <router-view/>
})