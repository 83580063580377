/*
 * @Autor: suhang
 * @Date: 2022-05-17 15:42:30
 * @LastEditors: suhang
 * @LastEditTime: 2022-09-15 19:19:08
 * @Description:
 */
import "lib-flexible"
import { createApp } from "vue"
import App from "./App"
import router from "./router"
import { i18n } from "./i18n/index"
import { createPinia } from "pinia"

const pinia = createPinia()
createApp(App).use(pinia).use(i18n).use(router).mount("#app")