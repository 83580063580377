/*
 * @Autor: suhang
 * @Date: 2022-05-20 14:56:52
 * @LastEditors: suhang
 * @LastEditTime: 2022-06-15 12:00:16
 * @Description: http请求工具类
 */

import Utils from "@utils";
import axios from "axios";

// 创建axios实例
const axiosInstance = axios.create({
	timeout: 40000, // 接口超时时间，默认40S
	baseURL: "/api",
});

// 设置请求拦截器
axiosInstance.interceptors.request.use(
	(config) => {
		if (config.headers) {
			console.log('get', sessionStorage.getItem("access_token"))
			// 设置token
			config.headers["Blade-Auth"] = "Bearer " + (sessionStorage.getItem("access_token") || "");
		}
		return config;
	},
	(err) => Promise.reject(err)
);

// 设置返回拦截器
axiosInstance.interceptors.response.use(
	(res) => {
		// TODO
		return res;
	},
	(err) => {
		// TODO
	}
);

export const HttpService = {
	get: axiosInstance.get,
	post: axiosInstance.post,
	put: axiosInstance.put,
	delete: axiosInstance.delete,
};
