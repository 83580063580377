/*
 * @Autor: suhang
 * @Date: 2022-09-15 17:58:10
 * @LastEditors: suhang
 * @LastEditTime: 2022-09-15 17:58:10
 * @Description: 
 */

import { defineComponent } from "vue";

export default defineComponent({
    render: () => <router-view/>
})